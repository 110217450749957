function App() {
  const goLinkedin = () => {
    window.open("https://www.linkedin.com/in/syazwanzubir/", "_blank");
  };

  const goGithub = () => {
    window.open("https://github.com/syazwanz", "_blank");
  };

  return (
    <div class="font-azeret-mono bg-greyey h-screen flex flex-col justify-center items-center p-5">
      <div>
        <button
          class="bg-gray-300 hover:bg-pinkey text-gray-800 hover:text-gray-50 font-semibold py-1 px-3 rounded inline-flex items-center mr-2 mb-2"
          onClick={goGithub}
        >
          <i class="fa fa-github text-xl mr-2" />
          <span>GitHub</span>
        </button>
        <button
          class="bg-gray-300 hover:bg-pinkey text-gray-800 hover:text-gray-50 font-semibold py-1 px-3 rounded inline-flex items-center"
          onClick={goLinkedin}
        >
          <i class="fa fa-linkedin-square text-xl mr-2" />
          <span>LinkedIn</span>
        </button>
      </div>

      <div class="text-4xl mb-5 ">
        Hello<span class="text-pinkey text-6xl -ml-3">.</span>I'm Syazwan
        <span class="text-pinkey text-6xl -ml-3">.</span>
      </div>

      <div>Software Developer based in KL, MY</div>
    </div>
  );
}

export default App;
